import { getFloor } from '@/api/rim/device'

export const bAssetsMixins = {
  methods: {
    /**
     * 根据站点id获取楼层
     * @param {Number} stationId 站点id
     * @returns
     */
    async getFloorData (stationId) {
      const result = await getFloor({ stationId })
      return result || []
    }
  }
}
