<template>
  <div>
    <Modal v-model="show" :width="800">
      <p slot="header" class="text-center">资产详情</p>
      <h4 class="workplatform-title m-t-10 m-b-10">基础信息</h4>
      <Row :gutter="8" class="p-b-10">
        <i-col span="8">
          <label class="title">编号</label><span>{{ assetInfo.code }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">资产名称</label><span>{{ assetInfo.assetName }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">所属类型</label>
          <span>{{ assetInfo.deviceBigmodelName + ' - ' + assetInfo.devicemodelName }}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="8">
          <label class="title">租赁类型</label>
          <span>{{ assetInfo.openLease ? '开放租赁' : '自用' }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">位置</label><span>{{ assetInfo.positionName }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">朝向</label><span>{{ assetInfo.orientationName }}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="8">
          <label class="title">楼层</label>
          <span>{{ assetInfo.floor }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">建筑面积</label><span>{{ assetInfo.buildArea + ' ㎡' }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">实用面积</label>
          <span>{{ assetInfo.useArea + ' ㎡'}}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="8">
          <label class="title">管理单位</label>
          <span>{{ assetInfo.manageUnitName }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">物业单位</label>
          <span>{{ assetInfo.manageUnitName }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">规划用途</label>
          <span>{{ assetInfo.useName }}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="8">
          <label class="title">状态</label>
          <span>{{ assetInfo.enabledName }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">移交日期</label>
          <span>{{ assetInfo.transferTime||'/' }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">经营时间</label>
          <span>{{ usagesTime }}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="8">
          <label class="title">附加设备</label>
          <span>{{ assetInfo.facilityNameList?assetInfo.facilityNameList.toString():'/' }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">创建时间</label>
          <span>{{ assetInfo.createTime }}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="24">
          <label class="title">防火要求</label>
          <span>{{ assetInfo.fireproof||'/' }}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="24">
          <label class="title">气味要求</label>
          <span>{{ assetInfo.smell||'/' }}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="24">
          <label class="title">疏散要求</label>
          <span>{{ assetInfo.evacuation||'/' }}</span>
        </i-col>
      </Row>
    </Modal>
  </div>
</template>
<script>
export default {
  data () {
    return {
      show: false,
      assetInfo: {}
    }
  },
  computed: {
    usagesTime () {
      if (this.assetInfo.usagesTime && this.assetInfo.usagesTime !== '' && Array.isArray(JSON.parse(this.assetInfo.usagesTime))) {
        const times = JSON.parse(this.assetInfo.usagesTime)
        return `${times[0]} - ${times[1]}`
      } else {
        return '/'
      }
    }
  },
  methods: {
    showModal (obj) {
      this.assetInfo = obj
      this.show = true
    }
  }
}
</script>
