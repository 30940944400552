import { dictionary } from '@/api/sys/dictbiz'
export const dictbizMixins = {

  methods: {
    /**
     * 获取各类型-状态数据字典
     * @param {string} param 参数
     * @returns
     */
    async getDictionary (param) {
      const result = await dictionary(param)
      return (result && result.success ? result.data : [])
    }
  }
}
