<template>
  <div>
    <Modal
      v-model="modifyShow"
      width="800"
      footer-hide
    >
      <p
        slot="header"
        class="text-center"
      >{{ optionType === 1 ? '新增商业资产' : '编辑商业资产' }}</p>
      <Form
        ref="formData"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="8">
            <FormItem
              label="编号"
              prop="code"
            >
              <i-input
                size="small"
                v-model="formData.code"
                placeholder="编号"
              />
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem
              label="所属线路"
              prop="assetId"
            >
              <Select
                size="small"
                placeholder="选择所属线路"
                v-model="formData.assetId"
                @on-change="handleChangeAsset"
              >
                <i-option
                  v-for="item in assetArray"
                  :key="'asset_' + item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <!-- <i-col span="8">
            <FormItem label="资产大类" prop="deviceBigmodel">
              <Select size="small" placeholder="资产大类" :disabled="!formData.assetId" v-model="formData.deviceBigmodel">
                <i-option v-for="item in parentDeviceModelArray" :key="'bigmodel_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col> -->
          <i-col span="8">
            <FormItem
              label="资产类型"
              prop="devicemodel"
            >
              <Select
                size="small"
                placeholder="选择资产类型"
                :disabled="!formData.assetId"
                v-model="formData.devicemodel"
              >
                <i-option
                  v-for="item in childDeviceModelArray"
                  :key="'sModel_' + item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="8">
            <FormItem
              label="所属站点"
              prop="stationId"
            >
              <Select
                size="small"
                placeholder="选择站点"
                :disabled="!formData.assetId"
                v-model="formData.stationId"
                @on-change="onChangeStation"
              >
                <i-option
                  v-for="item in stationArray"
                  :key="'station_' + item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem
              label="所属位置"
              prop="position"
            >
              <Select
                size="small"
                placeholder="选择位置"
                v-model="formData.position"
              >
                <i-option
                  v-for="item in positionArray"
                  :key="'position_' + item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem
              label="所属楼层"
              prop="floorId"
            >
              <Select
                size="small"
                placeholder="选择楼层"
                v-model="formData.floorId"
              >
                <i-option
                  v-for="item in floorArray"
                  :key="'floor_' + item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="8">
            <FormItem
              label="管理单位"
              prop="manageUnit"
            >
              <Select
                size="small"
                placeholder="选择管理单位"
                v-model="formData.manageUnit"
              >
                <i-option
                  v-for="item in manageCompanyArray"
                  :key="'com_' + item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem
              label="物业单位"
              prop="property"
            >
              <Select
                size="small"
                placeholder="选择物业单位"
                v-model="formData.property"
              >
                <i-option
                  v-for="item in propertyCompanyArray"
                  :key="'property_' + item.dictKey"
                  :value="item.dictKey"
                >
                  {{ item.dictValue }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="移交日期">
              <DatePicker
                size="small"
                v-model="formData.transferTime"
                type="date"
                style="width:100%"
                placeholder="选择移交日期"
              ></DatePicker>
            </FormItem>
            <!-- <FormItem label="出口">
              <i-input
                size="small"
                v-model="formData.exits"
                placeholder="出口"
              />
            </FormItem> -->
          </i-col>
        </Row>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="8">
            <FormItem label="建筑面积（㎡）">
              <InputNumber
                size="small"
                :max="99999"
                :min="0"
                v-model="formData.buildArea"
                style="width:100%"
                placeholder="建筑面积"
              >
              </InputNumber>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="实用面积（㎡）">
              <InputNumber
                size="small"
                :max="99999"
                :min="0"
                v-model="formData.useArea"
                style="width:100%"
                placeholder="实用面积"
              >
              </InputNumber>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="租赁类型">
              <Select
                size="small"
                placeholder="租赁类型"
                v-model="formData.openLease"
              >
                <i-option :value="1">开放租赁</i-option>
                <i-option :value="0">自用</i-option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <!-- <Row :gutter="8" class="m-b-10">
          <i-col span="8">
            <FormItem label="长度（mm）">
              <InputNumber size="small" :max="999999" :min="0" v-model="formData.width" style="width:100%"
                placeholder="长度"></InputNumber>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="宽度（mm）">
              <InputNumber size="small" :max="999999" :min="0" v-model="formData.height" style="width:100%"
                placeholder="宽度"></InputNumber>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="高度（mm）">
              <InputNumber size="small" :max="999999" :min="0" v-model="formData.depth" style="width:100%"
                placeholder="高度"></InputNumber>
            </FormItem>
          </i-col>
        </Row> -->
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="8">
            <FormItem label="规划用途">
              <Select
                size="small"
                placeholder="选择规划用途"
                v-model="formData.uses"
              >
                <i-option
                  v-for="item in usesArray"
                  :key="'uses_' + item.dictKey"
                  :value="item.dictKey"
                >
                  {{ item.dictValue }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="状态">
              <Select
                size="small"
                placeholder="选择状态"
                v-model="formData.enabled"
              >
                <i-option
                  v-for="item in statusArray"
                  :key="'status_' + item.dictKey"
                  :value="Number(item.dictKey)"
                >
                  {{ item.dictValue }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="经营时间">
              <TimePicker
                size="small"
                v-model="formData.usagesTime"
                type="timerange"
                placement="bottom-end"
                placeholder="选择经营时间"
                style="width: 100%"
              ></TimePicker>
            </FormItem>
          </i-col>
        </Row>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="8">
            <FormItem label="朝向">
              <Select
                size="small"
                placeholder="选择朝向"
                v-model="formData.orientation"
              >
                <i-option
                  v-for="item in orientationArray"
                  :key="'orien_' + item.dictKey"
                  :value="Number(item.dictKey)"
                >
                  {{ item.dictValue }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="附加设备(多选)">
              <Select
                size="small"
                multiple
                placeholder="选择附加设备"
                v-model="formData.facilities"
              >
                <i-option
                  v-for="item in facilityArray"
                  :key="'orien_' + item.dictKey"
                  :value="item.dictKey"
                >
                  {{ item.dictValue }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="12">
            <FormItem label="防火要求">
              <Input
                v-model="formData.fireproof"
                show-word-limit
                style="width:100%"
                size="small"
                type="textarea"
                maxlength='200'
                placeholder="输入防火要求"
              />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="气味要求">
              <Input
                v-model="formData.smell"
                show-word-limit
                style="width:100%"
                size="small"
                type="textarea"
                maxlength='200'
                placeholder="输入气味要求"
              />
            </FormItem>
          </i-col>
        </Row>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="12">
            <FormItem label="疏散要求">
              <Input
                v-model="formData.evacuation"
                show-word-limit
                style="width:100%"
                size="small"
                type="textarea"
                maxlength='200'
                placeholder="输入疏散要求"
              />
            </FormItem>
          </i-col>
        </Row>
        <FormItem class="text-right m-t-20">
          <Button
            type="text"
            class="m-r-5"
            @click="handleCancel"
          >取消</Button>
          <Button
            type="primary"
            :loading="submitLoading"
            @click="handleSubmit"
          >确认录入</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
import { getDeviceModelList, getPositionList } from '@/api/rim/asset'
import { getStationByAssetId } from '@/api/rim/station'
import { getCompanyList } from '@/api/os/company'
import { submit } from '@/api/rim/device'
import { ParseDate } from '@/utils/dateFormat'
import { dictbizMixins } from '@/assets/mixins/dictbiz'
import { bAssetsMixins } from './mixins/common'
// import { getFloor } from '@/api/rim/device'
const getFomrData = () => {
  return {
    area: 0,
    assetId: null,
    certNo: '未办理',
    code: '',
    depth: 0,
    deviceBigmodel: 3,
    devicemodel: undefined,
    electric: 1,
    enabled: 1,
    exits: '',
    facilities: [], // 附加设备
    fault: false,
    fireApproval: 1,
    floorId: undefined,
    gasSupply: 1,
    height: 0,
    id: undefined,
    innerCode: '',
    manageUnit: null,
    model: '',
    name: '',
    orientation: '',
    position: null,
    publisherId: 0,
    quantity: 0,
    remark: '',
    street: '',
    stationId: undefined,
    transferTime: '',
    unit: '',
    uses: '',
    usingUnit: 0,
    waterSupply: 1,
    width: 0,
    openLease: 1,
    buildArea: 0,
    useArea: 0,
    property: undefined, // 物业\
    fireproof: '', // 防火要求
    smell: '', // 气味要求
    evacuation: '', // 疏散要求
    usagesTime: '' // 经营时间
  }
}
export default {
  props: {
    optionType: {
      type: Number,
      default: 1 // 1：新增，2：编辑
    },
    curDeviceInfo: [Object],
    assetArray: [Array],
    // curDeviceInfo: [Object],
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  mixins: [dictbizMixins, bAssetsMixins],
  data () {
    return {
      modifyShow: false,
      parentDeviceModelArray: [],
      childDeviceModelArray: [],
      stationArray: [],
      floorArray: [],
      // trueFalseEnum: [
      //   { id: 1, name: '是' },
      //   { id: 0, name: '否' }
      // ],
      positionArray: [],
      manageCompanyArray: [],
      propertyCompanyArray: [], // 物业公司集合
      usesArray: [], // 规划用途集合
      statusArray: [], // 状态集合
      orientationArray: [], // 朝向集合
      facilityArray: [], // 附加设备集合
      formData: getFomrData(),
      formRules: {
        code: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        assetId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        devicemodel: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        stationId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        position: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        floorId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        manageUnit: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ]
      },
      submitLoading: false,
      publisherId: this.$store.getters.token.userInfo.publisherId
    }
  },
  methods: {
    async showModal () {
      // 重置数据
      Object.assign(this.formData, getFomrData())
      this.getCompanyData()

      if (this.optionType === 1) {
        // 新增
        this.getPositionData()
      } else {
        // 编辑
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = this.curDeviceInfo[key]
        })
        this.formData.floorId = this.curDeviceInfo.floorEntity.id
        this.formData.electric = Number(this.curDeviceInfo.electric)
        this.formData.openLease = Number(this.curDeviceInfo.openLease)
        this.formData.facilities = this.curDeviceInfo.facilities.split(',')
        this.formData.uses = this.curDeviceInfo.uses
        this.formData.usagesTime = this.curDeviceInfo.usagesTime !== '' && Array.isArray(JSON.parse(this.curDeviceInfo.usagesTime)) ? JSON.parse(this.curDeviceInfo.usagesTime) : ''
        // this.formData.orientation = this.curDeviceInfo.orientation.toString()
        this.$nextTick(() => {
          this.handleChangeAsset()
          this.onChangeStation()
        })
      }
      this.modifyShow = true
      this.propertyCompanyArray = await this.getDictionary({ code: 'rim_estate_property', publisherId: this.publisherId })
      this.usesArray = await this.getDictionary({ code: 'device_use', publisherId: this.publisherId })
      this.statusArray = await this.getDictionary({ code: 'rim_estate_status', publisherId: this.publisherId })
      this.orientationArray = await this.getDictionary({ code: 'house_orientation', publisherId: this.publisherId })
      this.facilityArray = await this.getDictionary({ code: 'device_facility', publisherId: this.publisherId })
    },
    handleChangeAsset () {
      // this.formData.position = null
      this.getBigModelData()
      this.getStationData()
      this.getPositionData()
    },
    getBigModelData () {
      if (!this.formData.assetId) {
        return false
      }
      getDeviceModelList({ assetId: this.formData.assetId, deviceBigModel: this.formData.deviceBigModel }).then(res => {
        if (res && !res.errcode) {
          this.parentDeviceModelArray = res
          // this.formData.deviceBigmodel = this.parentDeviceModelArray.length ? this.parentDeviceModelArray[0].id : undefined
          this.handleChangeBigmodel()
        } else {
          this.parentDeviceModelArray = []
        }
      })
    },
    handleChangeBigmodel () {
      const result = this.parentDeviceModelArray.find(x => x.id === this.formData.deviceBigmodel)
      this.childDeviceModelArray = result && result.children ? result.children : []
    },
    getStationData () {
      getStationByAssetId({ assetId: this.formData.assetId }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    async onChangeStation () {
      this.floorArray = []
      if (this.formData.stationId) {
        this.floorArray = await this.getFloorData(this.formData.stationId)
      }
    },
    getPositionData () {
      const assetIds = !this.formData.assetId ? this.assetArray.map(x => x.id) : [this.formData.assetId]
      getPositionList({ assetIds: JSON.stringify(assetIds) }).then(res => {
        if (res && !res.errcode) {
          this.positionArray = res
        } else {
          this.positionArray = []
        }
      })
    },
    getCompanyData () {
      const params = { enabled: true, publisherId: this.$store.getters.token.userInfo.publisherId }
      getCompanyList(params).then(res => {
        if (res && !res.errcode) {
          this.manageCompanyArray = res
        } else {
          this.manageCompanyArray = []
        }
      })
    },
    handleCancel () {
      this.modifyShow = false
      this.$refs.formData.resetFields()
    },
    handleSubmit () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确定信息无误并提交？',
            onOk: async () => {
              this.submitLoading = true
              const postData = Object.assign({}, this.formData)
              postData.transferTime = this.formData.transferTime ? ParseDate(this.formData.transferTime) : ''
              postData.facilities = this.formData.facilities.toString()
              postData.usagesTime = this.formData.usagesTime ? JSON.stringify(this.formData.usagesTime) : ''
              postData.uses = this.formData.uses

              const result = await submit(JSON.stringify(postData)).finally(() => {
                this.submitLoading = false
                this.modifyShow = false
              })
              if (result && result.errcode === 0) {
                this.$Notice.success({ title: '操作提示', desc: '提交成功' })
                this.onSuccess()
              } else {
                this.$Notice.error({ title: '操作提示', desc: '提交失败' })
              }
            }
          })
        }
      })
    }
  }
}
</script>
