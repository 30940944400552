<style>
.demo-upload-list {
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
<template>
  <div>
    <Modal
      v-model="modelShow"
      width="600"
    >
      <p
        slot="header"
        class="text-center"
      >资产图片管理</p>
      <div>
        <Upload
          ref="upload"
          :headers="headers"
          :action="uploadUrl"
          :show-upload-list="false"
          :before-upload="onBeforeUpload"
          :on-progress="onProgress"
          :on-success="onUploadSuccess"
          :format="['jpg', 'jpeg', 'png']"
          accept=".jpeg,.jpg,.png"
          type="drag"
          style="display: inline-block;width:100%;"
        >
          <!-- <div style="width: 98px;height:98px;line-height: 98px;">
            <Icon
              type="ios-add"
              size="20"
            ></Icon>
          </div> -->
          <div style="padding: 20px 0;width:100%">
            <Icon
              type="ios-cloud-upload"
              size="52"
              style="color: #3399ff"
            ></Icon>
            <!-- <p>Click or drag files here to upload</p> -->
          </div>
        </Upload>
        <div class="m-t-10">
          <p class="text-orange">只支持图片上传，图片上传格式为：jpg、jpeg、 png，图片大小不超过20M。</p>
        </div>
        <div
          class="demo-upload-list"
          v-for="(item, index) in defaultFiles"
          :key="'default_' + index"
        >
          <img :src="item.photo + '?x-oss-process=image/resize,w_100'">
          <div class="demo-upload-list-cover">
            <Icon
              type="ios-eye-outline"
              @click="onViewFile(item.photo)"
            ></Icon>
            <!-- <Icon
              type="ios-trash-outline"
              @click="onRemoveFile(item)"
            ></Icon> -->
          </div>
        </div>
        <div
          class="demo-upload-list"
          v-for="(item, index) in uploadFiles"
          :key="'file_' + index"
        >
          <template v-if="item.status==='finished'">
            <img :src="item.response.data.link + '?x-oss-process=image/resize,w_100'">
            <div class="demo-upload-list-cover">
              <Icon
                type="ios-eye-outline"
                @click.native="onViewFile(item.response.data.link)"
              ></Icon>
              <!-- <Icon
                  type="ios-trash-outline"
                  @click.native="onRemoveFile(item)"
                ></Icon> -->
            </div>
          </template>
          <template v-else>
            <Progress
              v-if="item.showProgress"
              :percent="item.percentage"
              hide-info
            ></Progress>
          </template>
        </div>
      </div>
      <div
        slot="footer"
        class="text-right"
      >
        <Button
          type="text"
          class="m-r-5"
          @click="modelShow = false"
        >取消</Button>
        <Button
          type="primary"
          @click="onSubmit"
        >保存并提交</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getStorage } from '@/utils/storage'
import { getDevicePhoto, setDevicePhoto } from '@/api/rim/device'
export default {
  props: {
    deviceId: [Number]
  },
  data () {
    return {
      modelShow: false,
      uploadUrl: `${process.env.VUE_APP_API_URL_V2}/ooh-resource/oss/endpoint/put-file`,
      headers: {
        'ooh-auth': getStorage('ooh-token')
      }, // 上传组件设置协议头
      defaultFiles: [],
      uploadFiles: [],
      uploadFileUrl: ''
    }
  },
  methods: {
    showModal () {
      this.uploadFiles = []
      this.getDevicePhotoData()
      this.modelShow = true
    },
    async getDevicePhotoData () {
      this.defaultFiles = []
      const result = await getDevicePhoto({ deviceId: this.deviceId })
      this.defaultFiles = result
    },
    onBeforeUpload (file) {
      this.defaultFiles = []
      if (file.type.indexOf('image/') > -1 && file.size > 1024 * 1024 * 20) { // 单个文件不超过20M
        this.$Notice.warning({ desc: '文件名为“' + file.name + '”的文件上传失败，单个文件不超过20M' })
        return false
      }
    },
    onProgress (event, file, fileList) {
      this.uploadFiles = [file]
      event.target.onprogress = (e) => {
        const uploadPercent = parseFloat(((e.loaded / e.total) * 100).toFixed(2))
        file.showProgress = true
        file.percentage = uploadPercent
      }
    },
    onUploadSuccess (response) {
      if (response && response.success) {
        this.uploadFileUrl = response.data.link
      }
    },
    onViewFile (fileUrl) {
      window.open(fileUrl)
    },
    onSubmit () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定信息无误并提交？',
        onOk: async () => {
          const result = await setDevicePhoto({ deviceId: this.deviceId, photos: JSON.stringify([this.uploadFileUrl]) })
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.modelShow = false
          }
        }
      })
    }
  }
}
</script>
